import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import ReactHtmlParser from "react-html-parser";
import "../Lesson.css";
import LottieAnimation from "../LottieAnimation";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import LessonMainSideNav from "../sidebar/LessonMainSideNav";
import LessonExamplePreview from "../quizBuilder/LessonExamplePreview";

const LessonPreview = ({
  lesson,
  currentSectionIndex,
  setCurrentSectionIndex,
  quizzes,
}) => {
  const { isDarkMode } = useAmazonContext();
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isShowQuiz, setIsShowQuiz] = useState(false);
  const previewRef = useRef(null); // Ref for the lesson-preview container

  const handleNextSection = () => {
    setCurrentSectionIndex((prevIndex) => prevIndex + 1);
  };

  const handleShowLessonExample = () => {
    setIsShowQuiz(true);
  };

  console.log(lesson);

  const convertToCloudFrontUrl = (s3Url) => {
    if (!s3Url) return s3Url;
    return s3Url.replace(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com",
      "https://d10jmd9bhq0s4v.cloudfront.net"
    );
  };

  useEffect(() => {
    if (isShowQuiz) {
      const lessonExampleElement = document.getElementById("lessonExample");
      if (lessonExampleElement && previewRef.current) {
        const scrollOffset =
          lessonExampleElement.offsetTop - previewRef.current.offsetTop;
        previewRef.current.scrollTo({
          top: scrollOffset,
          behavior: "smooth",
        });
      }
    }
  }, [isShowQuiz]);

  // Scroll upon click, current section is at the 30% from top of screen
  useEffect(() => {
    if (currentSectionIndex >= 0) {
      const currentSection = document.getElementById(
        `section-${currentSectionIndex}`
      );
      if (currentSection && previewRef.current) {
        const rect = currentSection.getBoundingClientRect();
        const offset =
          rect.top +
          previewRef.current.scrollTop -
          previewRef.current.clientHeight * 0.3;
        previewRef.current.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }, [currentSectionIndex]);

  useEffect(() => {
    setIsButtonVisible(false);
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentSectionIndex]);

  return lesson === "undefined" || lesson === null || lesson.length === 0 ? (
    <div>Loading</div>
  ) : (
    <div className="lesson-preview" ref={previewRef}>
      <LessonMainSideNav></LessonMainSideNav>
      <div className="Lesson">
        <div className="LessonContainer" style={{ width: "100%" }}>
          <div style={{ margin: "20px" }} className="LessonTitleContainer">
            <span className="LessonNumber">Lesson {lesson.sectionNumber}</span>{" "}
            <br />
            <span className="LessonTitle">{lesson.sectionTitle}</span>
          </div>

          {lesson.lessonAnimation &&
            lesson.lessonAnimation.map(
              (section, index) =>
                // Conditionally render sections based on the index
                index <= currentSectionIndex &&
                (section.className == "multiSection" ? (
                  <motion.div
                    id={`section-${index}`}
                    className={section.className}
                    style={section.style}
                    initial={section.initial}
                    animate={section.animate}
                    exit={section.exit}
                    transition={section.transition}
                    key={index}
                  >
                    {section.subSections.map((subSection, subSectionIndex) => (
                      <motion.div
                        id={`subSection-${index}`}
                        className={subSection.className}
                        style={subSection.style}
                        initial={subSection.initial}
                        animate={subSection.animate}
                        exit={subSection.exit}
                        transition={subSection.transition}
                        key={index}
                      >
                        {subSection.miniSections.map(
                          (miniSection, miniSectionIndex) =>
                            miniSection.className === "LottieAnimation" ? (
                              <LottieAnimation
                                key={miniSectionIndex}
                                lottieFile={convertToCloudFrontUrl(
                                  miniSection.lottieFilePath
                                )}
                                className={miniSection.className}
                                style={miniSection.style}
                                initial={miniSection.initial}
                                animate={miniSection.animate}
                                exit={miniSection.exit}
                                transition={miniSection.transition}
                                speed={miniSection.speed}
                                loop={miniSection.loop}
                              ></LottieAnimation>
                            ) : miniSection.className ===
                              "sectionDescription image" ? (
                              <motion.div
                                key={miniSectionIndex}
                                className={`${miniSection.className} ${
                                  isDarkMode
                                    ? "darkThemeFont"
                                    : "lightThemeFont"
                                }`}
                                style={miniSection.style}
                                initial={miniSection.initial}
                                animate={miniSection.animate}
                                exit={miniSection.exit}
                                transition={miniSection.transition}
                              >
                                <div style={miniSection.contentStyle}>
                                  <img
                                    src={convertToCloudFrontUrl(
                                      miniSection.imageFilePath
                                    )}
                                    alt="noImage"
                                  ></img>
                                </div>
                                {/* Use subSection.content here */}
                              </motion.div>
                            ) : (
                              <motion.div
                                key={miniSectionIndex}
                                className={`${miniSection.className} ${
                                  isDarkMode
                                    ? "darkThemeFont"
                                    : "lightThemeFont"
                                }`}
                                style={miniSection.style}
                                initial={miniSection.initial}
                                animate={miniSection.animate}
                                exit={miniSection.exit}
                                transition={miniSection.transition}
                              >
                                <div style={miniSection.contentStyle}>
                                  {ReactHtmlParser(miniSection.content)}{" "}
                                </div>
                                {/* Use subSection.content here */}
                              </motion.div>
                            )
                        )}
                      </motion.div>
                    ))}
                  </motion.div>
                ) : (
                  <motion.div
                    id={`section-${index}`}
                    className={section.className}
                    style={section.style}
                    initial={section.initial}
                    animate={section.animate}
                    exit={section.exit}
                    transition={section.transition}
                    key={index}
                  >
                    {section.subSections.map((subSection, subSectionIndex) =>
                      subSection.className == "YouTubeVideo" ? (
                        <div style={subSection.style}>
                          <div className="ratio ratio-16x9">
                            <iframe
                              src={subSection.videoLink}
                              title="YouTube video"
                              allowFullScreen
                              style={{ width: "100%", height: "100%" }}
                              allow="autoplay" // Add this line
                            ></iframe>
                          </div>
                        </div>
                      ) : subSection.className == "LottieAnimation" ? (
                        <LottieAnimation
                          key={subSectionIndex}
                          lottieFile={convertToCloudFrontUrl(
                            subSection.lottieFilePath
                          )}
                          className={subSection.className}
                          style={subSection.style}
                          initial={subSection.initial}
                          animate={subSection.animate}
                          exit={subSection.exit}
                          transition={subSection.transition}
                          speed={subSection.speed}
                          loop={subSection.loop}
                        ></LottieAnimation>
                      ) : subSection.className ===
                        "sectionDescription image" ? (
                        <motion.div
                          key={subSectionIndex}
                          className={`${subSection.className} ${
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }`}
                          style={subSection.style}
                          initial={subSection.initial}
                          animate={subSection.animate}
                          exit={subSection.exit}
                          transition={subSection.transition}
                        >
                          <div style={subSection.contentStyle}>
                            <img
                              src={convertToCloudFrontUrl(
                                subSection.imageFilePath
                              )}
                              alt="noImage"
                            ></img>
                          </div>
                          {/* Use subSection.content here */}
                        </motion.div>
                      ) : (
                        <motion.div
                          key={subSectionIndex}
                          className={`${subSection.className} ${
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }`}
                          style={subSection.style}
                          initial={subSection.initial}
                          animate={subSection.animate}
                          exit={subSection.exit}
                          transition={subSection.transition}
                        >
                          <div style={subSection.contentStyle}>
                            {ReactHtmlParser(subSection.content)}{" "}
                          </div>
                          {/* Use subSection.content here */}
                        </motion.div>
                      )
                    )}
                  </motion.div>
                ))
            )}
          {lesson.lessonAnimation && isShowQuiz && (
            <div style={{ paddingTop: "50px" }} id="lessonExample">
              {" "}
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 10,
                }}
              >
                <LessonExamplePreview
                  lessonNumber={lesson.sectionNumber}
                  exampleDatafromBackEnd={quizzes}
                  // sectionExample={chooseRandomExample(
                  // selectedLesson.sectionNumber
                  // )}
                ></LessonExamplePreview>
              </motion.div>
            </div>
          )}

          <div style={{ minHeight: "1000px", zIndex: 999 }}></div>
        </div>

        {lesson.lessonAnimation &&
          currentSectionIndex < lesson.lessonAnimation.length - 1 && (
            <div
              style={{
                bottom: "10%",
                width: "30%",
              }}
              className="forwardButton"
              onClick={handleNextSection}
            ></div>
          )}
        {lesson.lessonAnimation &&
          currentSectionIndex === lesson.lessonAnimation.length - 1 &&
          !isShowQuiz && (
            <div
              style={{
                bottom: "10%",
                width: "30%",
              }}
              className="forwardButton"
              onClick={handleShowLessonExample}
            ></div>
          )}

        {currentSectionIndex === -1 && (
          <motion.div
            className={`${
              isDarkMode
                ? "forwardButtonVisibleDark"
                : "forwardButtonVisibleLight"
            }`}
            style={{
              bottom: "10%",
              width: "30%",
            }}
            onClick={handleNextSection}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.5,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          >
            Tap anywhere
          </motion.div>
        )}
        {lesson.lessonAnimation &&
          currentSectionIndex > -1 &&
          currentSectionIndex < 1 &&
          lesson.lessonAnimation.length !== 1 &&
          isButtonVisible && (
            <motion.div
              className={`${
                isDarkMode
                  ? "forwardButtonVisibleDark"
                  : "forwardButtonVisibleLight"
              }`}
              style={{
                bottom: "10%",
                width: "30%",
              }}
              onClick={handleNextSection}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              Tap anywhere
            </motion.div>
          )}
      </div>
    </div>
  );
};

export default LessonPreview;
