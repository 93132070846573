import React, { useState, useEffect, useRef } from "react";
import api from "../api";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import {
  ChevronLeft,
  ChevronRight,
  Home,
  BookOpen,
  User,
  LogOut,
} from "lucide-react";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import BottomNavBar from "./BottomNavBar";
import LottieAnimation from "../LottieAnimation";

import {
  Container,
  Header,
  ScrollContainer,
  BookCover,
  CoverImage,
  NavButton,
} from "./landingPageSharedStyledComponents";

const CategoryContainer = styled.div`
  margin-bottom: 40px;
  position: relative;
`;

const CategoryTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;

  margin: 0;
  margin-left: 2vh;
  padding: 10px 0;
  margin-bottom: 15px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: #8747d4;
  }
`;

const CourseScrollContainer = styled(ScrollContainer)`
  margin-bottom: 20px;
  margin-left: 10px;

  @media only screen and (min-width: 601px) {
    padding: 0 40px;
  }
`;

const ScrollButton = styled.button`
  display: none;

  @media only screen and (min-width: 601px) {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    transition: background 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.9);
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
`;

const LottieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;

  > div {
    width: 100%;
    max-width: 300px;

    @media only screen and (min-width: 601px) {
      max-width: 50%;
    }
  }
`;

function CourseHomePage() {
  const {
    userRole,
    progressData,
    setProgressData,
    chapterProgressData,
    setChapterProgressData,
    isDarkMode,
    setNavOpen,
  } = useAmazonContext();
  const [coursesByCategory, setCoursesByCategory] = useState({});
  const [showArrows, setShowArrows] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const scrollContainerRefs = useRef({});

  useEffect(() => {
    setNavOpen(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    api.get(`/coursesData`).then((response) => {
      const sortedCourses = response.data.sort(
        (a, b) => a.courseNumber - b.courseNumber
      );

      const groupedCourses = sortedCourses.reduce((acc, course) => {
        if (course.courseIsActive) {
          if (!acc[course.courseCategory]) {
            acc[course.courseCategory] = [];
          }
          acc[course.courseCategory].push(course);
        }
        return acc;
      }, {});

      setCoursesByCategory(groupedCourses);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const checkScrollability = () => {
      Object.keys(coursesByCategory).forEach((category) => {
        const container = scrollContainerRefs.current[category];
        if (container) {
          const isScrollable = container.scrollWidth > container.clientWidth;
          setShowArrows((prev) => ({ ...prev, [category]: isScrollable }));
        }
      });
    };

    checkScrollability();
    window.addEventListener("resize", checkScrollability);

    return () => window.removeEventListener("resize", checkScrollability);
  }, [coursesByCategory]);

  const scroll = (direction, category) => {
    if (scrollContainerRefs.current[category]) {
      const scrollAmount = direction === "left" ? -160 : 160;
      scrollContainerRefs.current[category].scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const convertToCloudFrontUrl = (s3Url) => {
    if (!s3Url) return s3Url;
    return s3Url.replace(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com",
      "https://d10jmd9bhq0s4v.cloudfront.net"
    );
  };

  if (isLoading) {
    return (
      <div>
        <LottieContainer>
          <LottieAnimation
            lottieFile="https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/LottieFiles/loading.json"
            loop={true}
          ></LottieAnimation>
        </LottieContainer>
        <BottomNavBar></BottomNavBar>
      </div>
    );
  }

  return (
    <div>
      <Header className="sideNavChapterHeader">
        <p>HH Courses</p>
      </Header>

      <Container>
        {Object.entries(coursesByCategory).map(([category, courses]) => (
          <CategoryContainer key={category}>
            <CategoryTitle
              className={isDarkMode ? "darkThemeFont" : "lightThemeFont"}
            >
              {category}
            </CategoryTitle>
            {showArrows[category] && (
              <ScrollButton
                className="left"
                onClick={() => scroll("left", category)}
              >
                <ChevronLeft size={24} />
              </ScrollButton>
            )}
            <CourseScrollContainer
              ref={(el) => (scrollContainerRefs.current[category] = el)}
            >
              {courses.map((course) => (
                <BookCover
                  key={course.id}
                  to={`/${course.courseTitle.replace(/\s/g, "")}`}
                >
                  <CoverImage
                    src={convertToCloudFrontUrl(course.courseImage)}
                    alt={`${course.title} cover`}
                  />
                </BookCover>
              ))}
            </CourseScrollContainer>
            {showArrows[category] && (
              <ScrollButton
                className="right"
                onClick={() => scroll("right", category)}
              >
                <ChevronRight size={24} />
              </ScrollButton>
            )}
          </CategoryContainer>
        ))}
      </Container>
      <div style={{ minHeight: "100px" }}></div>
      <BottomNavBar></BottomNavBar>
    </div>
  );
}

export default CourseHomePage;
