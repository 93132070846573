import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import CourseContent from "./CourseContent";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import api from "../api";
import ColorThief from "colorthief";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faShare } from "@fortawesome/free-solid-svg-icons";
import { useHistory, Link } from "react-router-dom";
import StartCard from "./StartCard"; // Import the StartCard component

const Container = styled.div`
  max-width: 64rem;
  margin: 0 auto;
  padding: 1rem;
  padding-bottom: 5rem; // Add padding to prevent content from being hidden behind the fixed bar
`;

const AnimatedContainer = styled(motion.div)`
  max-width: 64rem;
  margin: 0 auto;
  padding: 1rem;
  padding-bottom: 5rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${(props) => (props.isDarkMode ? "white" : "black")};
  z-index: 10;
`;

const Header = styled.div`
  text-align: center;
`;

const CourseImage = styled.img`
  margin: 0 auto;
  width: 200px;
  height: auto;
  margin-bottom: 1rem;
`;

const CourseTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const AuthorName = styled.p`
  font-size: 1.125rem;
`;

const InfoBox = styled.div`
  background-color: #d2cfe6;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  color: #8747d4;
  font-weight: bold;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 3vh;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 1.25rem;
  font-size: 2vh;
`;

const ListItem = styled.li`
  margin-bottom: 0.25rem;
`;

const OrderedList = styled.ol`
  list-style-type: decimal;
  padding-left: 1.25rem;
`;

const SmallText = styled.p`
  font-size: 2vh;

  margin-top: 0.5rem;
`;

const Button = styled.button`
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2563eb;
  }
`;

const FixedBottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: white;
  border-top: 1px solid #8747d4;
  background: #8747d4;
  display: flex;
  justify-content: center;
`;

const StartCourseButton = styled(Button)`
  width: 100%;
  background-color: #10b981;

  &:hover {
    background-color: #059669;
  }

  @media only screen and (min-width: 601px) {
    width: 30%;
  }
`;

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1rem;
`;

const Tab = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${(props) => (props.active ? "#f3f4f6" : "transparent")};
  border: none;
  border-bottom: 2px solid
    ${(props) => (props.active ? "#3b82f6" : "transparent")};
  cursor: pointer;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  transition: all 0.2s;

  &:hover {
    background-color: #f3f4f6;
  }
`;

const TabContent = styled.div`
  padding: 1rem 0;
`;

const FullScreenContinueButton = styled.button`
  font-family: "Quicksand", sans-serif;
  padding: 10px 20px;
  font-size: 3vh;
  border: 0;
  border-radius: 5px;
  color: white;
  transition: all 0.25s ease;
  cursor: pointer;
  color: white;
  background-color: #8747d4;
  position: fixed;
  font-weight: bold;
  bottom: 20px;
  width: 80%;

  left: 50%;

  transform: translateX(-50%);

  &:active {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  }

  @media only screen and (min-width: 601px) {
    width: 20%;
  }
`;

const StartCardContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d2cfe6;
  z-index: 1000;
`;

const ShareButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${(props) => (props.isDarkMode ? "white" : "black")};
  z-index: 10;
`;

function CourseSummaryPage(props) {
  //set the courseTitle and courseDetails for whatever course the User is on and store in AmazonContext to be used across app
  let {
    courseTitleUserIsOn,
    setCourseTitleUserIsOn,
    courseDetails,
    setCourseDetails,
    topicDatafromBackEnd,
    setTopicDatafromBackEnd,
    exampleDatafromBackEnd,
    setExampleDatafromBackEnd,
    entryStore,
    setEntryStore,
    subContainer,
    progressData,
    setProgressData,
    chapterProgressData,
    setChapterProgressData,
    isDarkMode,
  } = useAmazonContext();

  const [activeTab, setActiveTab] = useState("about");
  const [numberOfQuizQuestions, setNumberOfQuizQuestions] = useState(0);
  const [numberOfLessons, setNumberOfLessons] = useState(0);
  const [numberOfVideos, setNumberOfVideos] = useState(0);
  const [approxCourseDuration, setApproxCourseDuration] = useState(0);
  const [newEntryStore, setNewEntryStore] = useState(null);
  const history = useHistory();
  const [isClosing, setIsClosing] = useState(false);
  const [showStartCard, setShowStartCard] = useState(false);
  const [textColor, setTextColor] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const startAudio = useRef(
    new Audio(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/Audio/CourseStart.mp3"
    )
  );

  // Function to handle Start button click
  const handleStartClick = () => {
    if (isLoggedIn) {
      setShowStartCard(true);
      startAudio.current.play();
    } else {
      const currentPath = history.location.pathname;
      history.push(`/LoginPage?redirect=${encodeURIComponent(currentPath)}`);
    }
  };
  const handleClose = () => {
    setIsClosing(true);

    // Reset context values
    setCourseTitleUserIsOn("");
    setCourseDetails(null);
    setTopicDatafromBackEnd([]);
    setExampleDatafromBackEnd([]);
    setEntryStore([]);

    setTimeout(() => {
      history.push("/"); // Adjust this path if needed
    }, 500); // Match this with the animation duration
  };

  useEffect(() => {
    startAudio.current.load();
  }, []);

  useEffect(() => {
    const loadCourseData = async () => {
      setIsLoading(true);
      setCourseTitleUserIsOn(props.match.params.courseTitle);

      // Clear existing data
      setNewEntryStore(null);
      setEntryStore([]);
      setTopicDatafromBackEnd([]);
      setExampleDatafromBackEnd([]);
      setCourseDetails(null);

      try {
        // Load course details first
        const courseResponse = await api.get("/coursesData");
        const allCourses = courseResponse.data;
        const courseChosen = allCourses.find(
          (o) =>
            o.courseTitle.replace(/\s/g, "") === props.match.params.courseTitle
        );
        setCourseDetails(courseChosen);

        if (courseChosen) {
          // Load all course data in parallel
          const [sideBarData, topicsData, examplesData] = await Promise.all([
            api.get(
              `/${props.match.params.courseTitle}/sideBarData?collectionName=${courseChosen.courseTopicsCollectionName}&modelName=${courseChosen.courseTopicsModelName}`
            ),
            api.get(
              `/${props.match.params.courseTitle}/topicsData?collectionName=${courseChosen.courseTopicsCollectionName}&modelName=${courseChosen.courseTopicsModelName}`
            ),
            api.get(
              `/examplesData?collectionName=${courseChosen.courseExamplesCollectionName}&modelName=${courseChosen.courseExamplesModelName}`
            ),
          ]);

          setEntryStore(sideBarData.data);
          setTopicDatafromBackEnd(topicsData.data);
          setExampleDatafromBackEnd(examplesData.data);
        }
      } catch (error) {
        console.error("Error loading course data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadCourseData();
  }, [props.match.params.courseTitle]);

  useEffect(() => {
    courseDetails &&
      api
        .get(
          `/${courseTitleUserIsOn}/topicsData?collectionName=${courseDetails.courseTopicsCollectionName}&modelName=${courseDetails.courseTopicsModelName}`
        )
        .then((response) => {
          //counting topic youtube links

          let numberOfLessons = 0;
          let numberOfTopicsWithSections = 0;
          let numberOfVideos = 0;

          for (let i = 0; i < response.data.length; i++) {
            numberOfLessons += response.data[i].sections.length;
            if (response.data[i].sections.length !== 0) {
              numberOfTopicsWithSections += 1;
            }
            if (response.data[i].topicYoutubeLink) {
              numberOfVideos += 1;
            }
          }

          setNumberOfLessons(numberOfLessons);
          setNumberOfVideos(numberOfVideos);
          let timeToCompleteEachLesson = 0.7;
          let timeToCompleteEachTopicQuiz = 1;
          let addedBufferTime = 1;
          const calculatedDuration = timeToCompleteEachLesson * numberOfLessons;
          setApproxCourseDuration(Math.ceil(calculatedDuration));
          console.log(response.data);
        });
    courseDetails &&
      api
        .get(
          `/examplesData?collectionName=${courseDetails.courseExamplesCollectionName}&modelName=${courseDetails.courseExamplesModelName}`
        )
        .then((response) => {
          // setExampleDatafromBackEnd(response.data);
          setNumberOfQuizQuestions(response.data.length);
          console.log(response.data);
        });
  }, [courseDetails]);
  console.log(entryStore, topicDatafromBackEnd, exampleDatafromBackEnd);

  // let numberOfLessons = 0;
  // let numberOfVideos = 0;
  let numberOfTopicsWithSections = 0;

  // counting section youtube links
  // for (let i = 0; i < topicDatafromBackEnd.length; i++) {
  //   numberOfLessons += topicDatafromBackEnd[i].sections.length;
  //   if (topicDatafromBackEnd[i].sections.length !== 0) {
  //     //if the topic has a section
  //     numberOfTopicsWithSections += 1;
  //     for (let j = 0; j < topicDatafromBackEnd[i].sections.length; j++) {
  //       if (topicDatafromBackEnd[i].sections[j].sectonYoutubeLink) {
  //         //count the sections with videos
  //         numberOfVideos += 1;
  //       }
  //     }
  //   }
  // }

  console.log(numberOfLessons);
  console.log(numberOfVideos);
  console.log(numberOfTopicsWithSections);

  console.log(topicDatafromBackEnd);

  // let numberOfQuizQuestions = exampleDatafromBackEnd.length;

  console.log(numberOfQuizQuestions);

  // let approxCourseDuration = timeToCompleteEachLesson * numberOfLessons;

  console.log(approxCourseDuration);

  async function calculateDominantColor(imageUrl) {
    try {
      const cloudFrontUrl = convertToCloudFrontUrl(imageUrl);
      const response = await fetch(cloudFrontUrl, {
        method: "GET",
        headers: { "Cache-Control": "no-cache" },
      });
      if (response.ok) {
        const blob = await response.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
              const colorThief = new ColorThief();
              const dominantColorRGB = colorThief.getColor(image);
              resolve(`rgb(${dominantColorRGB.join(",")})`);
            };
          };
          reader.readAsDataURL(blob);
        });
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  }

  async function addDominantColors(entryStore) {
    return Promise.all(
      entryStore.map(async (topic) => {
        const updatedEntries = await Promise.all(
          topic.entries.map(async (entry) => {
            if (entry.entries && entry.entries[0] && entry.entries[0].entries) {
              const firstSectionImage =
                entry.entries[0].entries[0].sectionImage;
              const dominantColor = await calculateDominantColor(
                firstSectionImage
              );
              return {
                ...entry,
                dominantColor, // Add dominantColor here
              };
            }
            return entry;
          })
        );
        return {
          ...topic,
          entries: updatedEntries,
        };
      })
    );
  }

  // In your CourseSummaryPage component:
  useEffect(() => {
    if (entryStore) {
      addDominantColors(entryStore)
        .then((newStore) => {
          setNewEntryStore(newStore);
          console.log("New Entry Store:", newStore); // Add this line to check the result
        })
        .catch((error) =>
          console.error("Error adding dominant colors:", error)
        );
    }
  }, [entryStore]);

  console.log(newEntryStore);

  useEffect(() => {
    if (newEntryStore?.[0]?.entries?.[0]?.dominantColor) {
      const luminance = calculateLuminance(
        newEntryStore?.[0]?.entries?.[0]?.dominantColor
      );
      setTextColor(luminance > 0.5 ? "#000" : "#fff");
    }
  }, [newEntryStore]);

  const calculateLuminance = (color) => {
    const rgb = color.match(/\d+/g);
    const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
    return luminance;
  };

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(storedIsLoggedIn === "true");
  }, []);

  const handleShare = async () => {
    const courseUrl = window.location.href;
    const courseTitle = courseDetails?.courseCardTitle || "This course";
    const shareText = `Check out ${courseTitle} on HH Courses!`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: courseTitle,
          text: shareText,
          url: courseUrl,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback for browsers that don't support the Web Share API
      try {
        const fullShareText = `${shareText}\n${courseUrl}`;
        await navigator.clipboard.writeText(fullShareText);
        alert("Course information copied to clipboard!");
      } catch (error) {
        console.error("Error copying to clipboard:", error);
      }
    }
  };

  const convertToCloudFrontUrl = (s3Url) => {
    if (!s3Url) return s3Url;
    return s3Url.replace(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com",
      "https://d10jmd9bhq0s4v.cloudfront.net"
    );
  };

  return (
    <AnimatePresence>
      {!isClosing && (
        <>
          <AnimatedContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.5,
            }}
          >
            {!showStartCard && (
              <>
                <CloseButton
                  onClick={() => history.goBack()}
                  isDarkMode={isDarkMode}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </CloseButton>
                <ShareButton onClick={handleShare} isDarkMode={isDarkMode}>
                  <FontAwesomeIcon icon={faShare} />
                </ShareButton>
                <Header>
                  <CourseImage
                    src={
                      courseDetails &&
                      convertToCloudFrontUrl(courseDetails.courseImage)
                    }
                    alt={courseDetails && courseDetails.courseCardTitle}
                  />
                  <CourseTitle
                    className={isDarkMode ? "darkThemeFont" : "lightThemeFont"}
                  >
                    {courseDetails && courseDetails.courseCardTitle}
                  </CourseTitle>
                  <AuthorName
                    className={isDarkMode ? "darkThemeFont" : "lightThemeFont"}
                  >
                    by {courseDetails && courseDetails.courseAuthor}
                  </AuthorName>
                </Header>

                <InfoBox>
                  <InfoItem
                    className={isDarkMode ? "darkThemeFont" : "lightThemeFont"}
                  >
                    {numberOfLessons}
                    <br /> Lessons
                  </InfoItem>
                  <InfoItem
                    className={isDarkMode ? "darkThemeFont" : "lightThemeFont"}
                  >
                    {approxCourseDuration} <br />
                    mins
                  </InfoItem>
                </InfoBox>

                <TabContainer>
                  <Tab
                    className={
                      activeTab !== "about"
                        ? isDarkMode
                          ? "darkThemeFont"
                          : "lightThemeFont"
                        : ""
                    }
                    active={activeTab === "about"}
                    onClick={() => setActiveTab("about")}
                  >
                    About
                  </Tab>
                  <Tab
                    className={
                      activeTab !== "chapters"
                        ? isDarkMode
                          ? "darkThemeFont"
                          : "lightThemeFont"
                        : ""
                    }
                    active={activeTab === "chapters"}
                    onClick={() => setActiveTab("chapters")}
                  >
                    Chapters
                  </Tab>
                </TabContainer>

                <TabContent>
                  {activeTab === "about" && (
                    <>
                      <Section>
                        <SectionTitle
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                        >
                          What's inside?
                        </SectionTitle>
                        <p
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                          style={{ fontSize: "2vh" }}
                        >
                          {courseDetails && courseDetails.courseDescription}
                        </p>
                      </Section>

                      <Section>
                        <SectionTitle
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                        >
                          You'll learn
                        </SectionTitle>
                        <List>
                          {courseDetails?.courseGoals?.map((goal, index) => (
                            <ListItem
                              className={
                                isDarkMode ? "darkThemeFont" : "lightThemeFont"
                              }
                              key={index}
                            >
                              {goal}
                            </ListItem>
                          ))}
                        </List>
                      </Section>

                      <Section>
                        <SectionTitle
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                        >
                          About {courseDetails?.courseAuthor}
                        </SectionTitle>
                        <p
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                          style={{ fontSize: "2vh" }}
                        >
                          {courseDetails?.courseAuthorBio}
                        </p>
                        <SmallText
                          className={
                            isDarkMode ? "darkThemeFont" : "lightThemeFont"
                          }
                        >
                          {courseDetails?.courseReferenceDescription}
                        </SmallText>
                      </Section>
                    </>
                  )}

                  {activeTab === "chapters" && (
                    <Section>
                      {isLoading ? (
                        <div
                          style={{
                            textAlign: "center",
                            padding: "2rem",
                            color: isDarkMode ? "#fff" : "#000",
                          }}
                        >
                          Loading course content...
                        </div>
                      ) : (
                        <CourseContent newEntryStore={newEntryStore} />
                      )}
                    </Section>
                  )}
                </TabContent>

                {courseDetails && courseDetails.pdfUrl && (
                  <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                    <Button>Get Full Book PDF</Button>
                  </div>
                )}

                <FullScreenContinueButton onClick={handleStartClick}>
                  {isLoggedIn ? "Start" : "Login to Start"}
                </FullScreenContinueButton>
              </>
            )}
          </AnimatedContainer>

          <AnimatePresence>
            {showStartCard && (
              <StartCardContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <StartCard
                  nextChapterNumber={
                    newEntryStore?.[0]?.entries?.[0]?.topicNumber ||
                    "First Lesson"
                  }
                  nextChapterTitle={
                    newEntryStore?.[0]?.entries?.[0]?.title || "First Lesson"
                  }
                  nextChapterFirstLessonNumber={
                    newEntryStore?.[0]?.entries?.[0]?.entries?.[0]?.entries?.[0]
                      ?.sectionNumber || "First Lesson"
                  }
                  nextChapterFirstLessonTitle={
                    newEntryStore?.[0]?.entries?.[0]?.entries?.[0]?.entries?.[0]
                      ?.title || "First Lesson"
                  }
                  nextChapterFirstLessonImage={
                    newEntryStore?.[0]?.entries?.[0]?.entries?.[0]?.entries?.[0]
                      ?.sectionImage || "First Lesson"
                  }
                  dominantColor={
                    newEntryStore?.[0]?.entries?.[0]?.dominantColor || "#967bb6"
                  }
                  textColor={textColor}
                  courseTitleUserIsOn={courseTitleUserIsOn}
                />
              </StartCardContainer>
            )}
          </AnimatePresence>
        </>
      )}
    </AnimatePresence>
  );
}

export default CourseSummaryPage;
