import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../api";
import styled from "styled-components";

const FullWidthContainer = styled.div`
  width: 100vw;
  position: relative;
`;

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 20px 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const BookCover = styled.div`
  flex: 0 0 auto;
  width: 200px;
  margin-right: 20px;
  scroll-snap-align: start;
  position: relative;

  &:first-child {
    margin-left: 20px;
  }
`;

const CoverImage = styled.img`
  width: 100%;
  height: ${200 * (2250 / 1410)}px; // Maintain aspect ratio
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
`;

function WelcomeMessageCourses() {
  const [courses, setCourses] = useState([]);

  const convertToCloudFrontUrl = (s3Url) => {
    if (!s3Url) return s3Url;
    return s3Url.replace(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com",
      "https://d10jmd9bhq0s4v.cloudfront.net"
    );
  };

  useEffect(() => {
    api.get(`/coursesData`).then((response) => {
      const sortedCourses = response.data
        .filter((course) => course.courseIsActive)
        .sort((a, b) => a.courseNumber - b.courseNumber)
        .map((course) => ({
          ...course,
          courseImage: convertToCloudFrontUrl(course.courseImage),
        }));
      setCourses(sortedCourses);
    });
  }, []);

  return (
    <div className="WelcomeMessageContainer">
      <div className="WelcomeMessageImagesContainer">
        <FullWidthContainer>
          <ScrollContainer>
            {courses.map((course) => (
              <BookCover key={course.id}>
                <CoverImage
                  src={course.courseImage}
                  alt={`${course.title} cover`}
                />
              </BookCover>
            ))}
          </ScrollContainer>
        </FullWidthContainer>
      </div>
      <div className="WelcomeMessageFooter">
        <div className="MessageTopSection">
          <div className="GetStartedHeader" style={{ fontSize: "4vh" }}>
            Authentically Sourced Courses
          </div>
          <div className="WelcomeMessageText" style={{ paddingTop: 0 }}>
            From top Scholars and Students of Knowledge!
          </div>
        </div>
        <div className="MessageMiddleSection">
          <Link
            to={"/WelcomeMessageSeven"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <button className="GetStartedButton">Continue</button>
          </Link>
        </div>
        <div className="MessageBottomSection"></div>
      </div>
    </div>
  );
}

export default WelcomeMessageCourses;
