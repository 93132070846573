import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../api";
import { useAmazonContext } from "../../Contexts/AmazonContext";
import { Home, BookOpen, User } from "lucide-react";
import LibraryProgressBar from "./LibraryProgressBar";
import styled from "styled-components";
import SideNavRowQuiz from "../sidebar/SideNavRowQuiz";
import SideNavRowLesson from "../sidebar/SideNavRowLesson";
import { motion } from "framer-motion";
import { Header, NavButton } from "./landingPageSharedStyledComponents";
import CourseProgressBar from "../sidebar/CourseProgressBar";
import BottomNavBar from "./BottomNavBar"; // Import the new BottomNavBar component
import LottieAnimation from "../LottieAnimation";

const LibraryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: calc(
    100vh - 150px
  ); // Adjust based on your header and footer heights
`;

const LottieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff; // Optional: add a background color

  > div {
    width: 100%;
    max-width: 300px; // Adjust this value as needed

    @media only screen and (min-width: 601px) {
      max-width: 50%;
    }
  }
`;

const BookWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  padding: 10px;
  margin-bottom: 30px;
  /* background-color: #d2cfe6; */
  border-radius: 0.5rem;
`;

const BookCover = styled.div`
  flex: 0 0 auto;
  width: 200px;
  margin: 10px auto; // This will center the BookCover horizontally

  scroll-snap-align: start;
  position: relative;
`;

const CoverImage = styled.img`
  width: 100%;

  height: ${200 * (2250 / 1410)}px; // Maintain aspect ratio
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
`;

function Library() {
  const [startedCourses, setStartedCourses] = useState([]);
  const [courseData, setCourseData] = useState({});
  const {
    progressData,
    chapterProgressData,
    setProgressData,
    setChapterProgressData,
  } = useAmazonContext();
  const [isLoading, setIsLoading] = useState(true);

  console.log(progressData);

  const userEmail = localStorage.getItem("userEmail");
  useEffect(() => {
    console.log(userEmail);
    if (!userEmail) {
      console.error("User email not found in local storage");
      return;
    }

    const fetchProgress = async () => {
      try {
        const response = await api.get("/fetchProgressRecord", {
          params: { userEmail: userEmail },
        });
        setProgressData(response.data);
      } catch (error) {
        console.error("Error fetching progress data:", error);
      }
    };

    const fetchChapterProgress = async () => {
      try {
        const response = await api.get("/fetchChapterProgressRecord", {
          params: { userEmail: userEmail },
        });
        setChapterProgressData(response.data);
      } catch (error) {
        console.error("Error fetching chapter progress data:", error);
      }
    };

    fetchProgress();
    fetchChapterProgress();
  }, [userEmail]);

  useEffect(() => {
    const fetchStartedCourses = async () => {
      if (!progressData || !chapterProgressData) {
        setIsLoading(false); // Set loading to false if data isn't loaded
        return; // Exit if data isn't loaded yet
      }

      setIsLoading(true);
      try {
        const response = await api.get("/coursesData");
        const allCourses = response.data;

        const coursesWithProgress = allCourses.filter((course) => {
          const courseProgress = progressData.find(
            (progress) =>
              progress.courseProgress === course.courseTitle.replace(/\s/g, "")
          );
          return (
            courseProgress &&
            courseProgress.lessonProgress &&
            courseProgress.lessonProgress.length > 0
          );
        });

        setStartedCourses(coursesWithProgress);

        // Fetch detailed data for each started course
        const courseDataPromises = coursesWithProgress.map(async (course) => {
          const courseTitle = course.courseTitle.replace(/\s/g, "");
          const sideBarResponse = await api.get(
            `/${courseTitle}/sideBarData?collectionName=${course.courseTopicsCollectionName}&modelName=${course.courseTopicsModelName}`
          );
          return { courseTitle, data: sideBarResponse.data };
        });

        const allCourseData = await Promise.all(courseDataPromises);
        const courseDataObj = {};
        allCourseData.forEach(({ courseTitle, data }) => {
          courseDataObj[courseTitle] = simplifyData(data);
        });
        setCourseData(courseDataObj);
      } catch (error) {
        console.error("Error fetching started courses:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStartedCourses();
  }, [progressData, chapterProgressData]);

  function simplifyData(data) {
    return (
      data &&
      data
        .map((topicHeader) => {
          return topicHeader.entries.map((chapter) => {
            return {
              chapterNumber: chapter.topicNumber,
              chapterTitle: chapter.title,
              lessons: chapter.entries[0].entries.map((lesson) => {
                return {
                  lessonNumber: lesson.sectionNumber,
                  lessonTitle: lesson.title,
                  lessonImage: lesson.sectionImage,
                };
              }),
            };
          });
        })
        .flat()
    );
  }

  function generateunlockedLessons(
    progressData,
    simplifiedChaptersLessonsData,
    chapterProgressData,
    courseTitleUserIsOn
  ) {
    const unlockedLessons = [];

    const userProgress =
      progressData &&
      progressData.find(
        (progress) => progress.courseProgress === courseTitleUserIsOn
      );
    if (userProgress) {
      const { lessonProgress } = userProgress;
      const lastCompletedLesson = lessonProgress[lessonProgress.length - 1];
      const chapterContainingLastCompletedLesson =
        simplifiedChaptersLessonsData &&
        simplifiedChaptersLessonsData.find((chapter) => {
          return chapter.lessons.some(
            (lesson) => lesson.lessonNumber === lastCompletedLesson
          );
        });

      if (chapterContainingLastCompletedLesson) {
        const { lessons } = chapterContainingLastCompletedLesson;
        const lastCompletedLessonIndex = lessons.findIndex(
          (lesson) => lesson.lessonNumber === lastCompletedLesson
        );
        if (lastCompletedLessonIndex === lessons.length - 1) {
          // Add all completed lessons and check chapter completion
          unlockedLessons.push(...lessonProgress);
          const nextChapterIndex =
            simplifiedChaptersLessonsData.findIndex(
              (chapter) =>
                chapter.chapterNumber ===
                chapterContainingLastCompletedLesson.chapterNumber
            ) + 1;
          const nextChapter = simplifiedChaptersLessonsData[nextChapterIndex];
          if (nextChapter) {
            const chapterProgress =
              chapterProgressData &&
              chapterProgressData.find(
                (progress) => progress.courseProgress === courseTitleUserIsOn
              )?.chapterProgress;
            if (
              chapterProgress &&
              chapterProgress.includes(
                chapterContainingLastCompletedLesson.chapterNumber
              )
            ) {
              const nextLesson = nextChapter.lessons[0];
              unlockedLessons.push(nextLesson.lessonNumber);
            }
          }
        } else {
          // Add completed lessons and next lesson in the same chapter
          const nextLesson = lessons[lastCompletedLessonIndex + 1];
          unlockedLessons.push(...lessonProgress, nextLesson.lessonNumber);
        }
      }
    }

    return unlockedLessons;
  }

  function getCourseDetails(courseTitle, simplifiedData) {
    const courseProgressData = progressData.find(
      (progress) => progress.courseProgress === courseTitle
    );
    const courseChapterProgressData = chapterProgressData.find(
      (progress) => progress.courseProgress === courseTitle
    );

    if (!courseProgressData || !courseChapterProgressData || !simplifiedData) {
      return null;
    }

    const unlockedLessons = generateunlockedLessons(
      [courseProgressData],
      simplifiedData,
      [courseChapterProgressData],
      courseTitle
    );
    const lastUnlockedLesson = unlockedLessons[unlockedLessons.length - 1];

    const lastCompletedLesson = courseProgressData.lessonProgress.slice(-1)[0];
    const chapterOfLastCompletedLesson = simplifiedData.find((chapter) =>
      chapter.lessons.some(
        (lesson) => lesson.lessonNumber === lastCompletedLesson
      )
    );

    const isLastLessonOfChapter =
      chapterOfLastCompletedLesson &&
      chapterOfLastCompletedLesson.lessons.slice(-1)[0].lessonNumber ===
        lastCompletedLesson;

    const chapterOfLastUnlockedLesson = simplifiedData.find((chapter) =>
      chapter.lessons.some(
        (lesson) => lesson.lessonNumber === lastUnlockedLesson
      )
    );

    const isFirstLessonOfChapter =
      chapterOfLastUnlockedLesson &&
      chapterOfLastUnlockedLesson.lessons[0].lessonNumber ===
        lastUnlockedLesson;

    const lastUnlockedLessonDetails = getLessonDetails(
      lastUnlockedLesson,
      simplifiedData
    );

    const isLessonProgressEmpty =
      courseProgressData.lessonProgress.length === 0;

    return {
      isLastLessonOfChapter,
      isFirstLessonOfChapter,
      chapterOfLastCompletedLesson,
      lastUnlockedLessonNumber: lastUnlockedLesson,
      lastUnlockedLessonTitle: lastUnlockedLessonDetails?.title,
      lastUnlockedLessonImage: lastUnlockedLessonDetails?.image,
      isLessonProgressEmpty,
    };
  }

  function getLessonDetails(lessonNumber, simplifiedData) {
    if (!simplifiedData) return null;

    for (let chapter of simplifiedData) {
      for (let lesson of chapter.lessons) {
        if (lesson.lessonNumber === lessonNumber) {
          return {
            title: lesson.lessonTitle,
            image: lesson.lessonImage,
          };
        }
      }
    }
    return null;
  }

  const convertToCloudFrontUrl = (s3Url) => {
    if (!s3Url) return s3Url;
    return s3Url.replace(
      "https://hhcourses-assets.s3.us-east-2.amazonaws.com",
      "https://d10jmd9bhq0s4v.cloudfront.net"
    );
  };

  if (isLoading) {
    return (
      <div>
        <LottieContainer>
          <LottieAnimation
            lottieFile={convertToCloudFrontUrl(
              "https://hhcourses-assets.s3.us-east-2.amazonaws.com/General/LottieFiles/loading.json"
            )}
            loop={true}
          ></LottieAnimation>
        </LottieContainer>
        <BottomNavBar></BottomNavBar>
      </div>
    );
  }

  if (!startedCourses.length) {
    return (
      <div>
        <Header className="sideNavChapterHeader">
          <p>My Library</p>
        </Header>
        <LibraryContainer>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: "1.5rem",
              textAlign: "center",
              padding: "20px",
            }}
          >
            You haven't started any courses yet!
          </p>
        </LibraryContainer>
        <BottomNavBar></BottomNavBar>
      </div>
    );
  }

  return (
    <div>
      <Header className="sideNavChapterHeader">
        <p>My Library</p>
      </Header>
      <LibraryContainer>
        {startedCourses.map((course) => {
          const courseTitle = course.courseTitle.replace(/\s/g, "");
          const simplifiedData = courseData[courseTitle];
          const courseDetails = getCourseDetails(courseTitle, simplifiedData);

          return (
            <BookWrapper key={course.id}>
              <BookCover>
                <CoverImage
                  src={convertToCloudFrontUrl(course.courseImage)}
                  alt={`${course.courseTitle} cover`}
                />
              </BookCover>

              <CourseProgressBar
                courseTitleUserIsOn={courseTitle}
                progressData={progressData}
                chapterProgressData={chapterProgressData}
                simplifiedChaptersLessonsData={simplifiedData}
              />
              {courseDetails &&
                (courseDetails.isLessonProgressEmpty ? (
                  <div></div>
                ) : courseDetails.isLastLessonOfChapter &&
                  !courseDetails.isFirstLessonOfChapter ? (
                  <div className="ContinueSideNavContainer">
                    <Link
                      to={`/${courseTitle}/quiz/${courseDetails.chapterOfLastCompletedLesson.chapterNumber}`}
                    >
                      <motion.div
                        whileTap={{ scale: 0.8 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 17,
                        }}
                      >
                        <motion.div
                          animate={{ y: [-5, 5, -5] }}
                          transition={{ duration: 2, repeat: Infinity }}
                        >
                          <SideNavRowQuiz
                            number={
                              courseDetails.chapterOfLastCompletedLesson
                                .chapterNumber
                            }
                            title={
                              courseDetails.chapterOfLastCompletedLesson
                                .chapterTitle
                            }
                            width="90%"
                            marginLeft="5%"
                          />
                        </motion.div>
                      </motion.div>
                    </Link>
                  </div>
                ) : (
                  <div className="ContinueSideNavContainer">
                    <Link
                      to={`/${courseTitle}/lesson/${courseDetails.lastUnlockedLessonNumber}`}
                    >
                      <motion.div
                        whileTap={{ scale: 0.8 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 17,
                        }}
                      >
                        <motion.div
                          animate={{ y: [-5, 5, -5] }}
                          transition={{ duration: 2, repeat: Infinity }}
                        >
                          <SideNavRowLesson
                            backgroundColor="white"
                            image={courseDetails.lastUnlockedLessonImage}
                            number={courseDetails.lastUnlockedLessonNumber}
                            title={courseDetails.lastUnlockedLessonTitle}
                            width="90%"
                            marginLeft="5%"
                            borderRadius="14% 2% 2% 14% / 50% 10% 10% 50%"
                          />
                        </motion.div>
                      </motion.div>
                    </Link>
                  </div>
                ))}
            </BookWrapper>
          );
        })}
      </LibraryContainer>
      <BottomNavBar></BottomNavBar>
    </div>
  );
}

export default Library;
